<template>
  <v-container fluid>
    <TheTitle text="User Groups" icon="mdi-group">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
          placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"            
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
            <v-btn block color="amber darken-4" dark @click="dialogSave()">
                <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
            </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="groups" :search="search" dense>
        <template v-slot:item.action="{ item }">
          <TheToolTip label="Editar Regras de menus">
            <v-btn icon small tile class="mr-2">
              <v-icon @click="editMenuRules(item)">mdi-playlist-edit</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Edit Rules">
            <v-btn icon small tile class="mr-2">
              <v-icon @click="editRules(item)"
                >mdi-clipboard-list-outline</v-icon
              >
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Edit Group">
            <v-btn icon small tile class="mr-2">
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remove">
            <v-btn icon small tile class="mr-2">
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>

      <TheDialog
        v-model="dialog"
        :title="!edit ? 'Add Group' : 'Update Group'"
        icon="mdi-group"
      >
        <template>
          <form>
            <TheInput
              label="Name"
              v-model="group.name"
              :error="error"
              css="my-2"
            />
          </form>
        </template>
        <template v-slot:actions="">
          <v-btn
            small
            depressed
            color="primary"
            class="mt-2"
            dark
            @click="resolveForm()"
          >
            <v-icon small class="mr-2" v-if="!edit">mdi-plus</v-icon>
            <span v-if="!edit">Salvar</span>
            <span v-if="edit">Atualizar</span>
          </v-btn>
        </template>
      </TheDialog>

      <TheDialog v-model="dialogRules" title="Update Rules" icon="">
        <template>
          <v-simple-table class="mb-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="20%">Routes</th>
                  <th class="text-center">Rules for {{ groupSelected.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in routes" :key="item.id">
                  <td width="20%">{{ item.name }}</td>
                  <td class="text-center">
                    <v-btn
                      dark
                      depressed
                      dense
                      small
                      class="mr-2"
                      :color="getColor(groupSelected.id, item.id, 'get')"
                      @click="setVerb(groupSelected.id, item.id, 'get')"
                    >
                      List and Get
                    </v-btn>
                    <v-btn
                      dark
                      depressed
                      dense
                      small
                      class="mr-2"
                      :color="getColor(groupSelected.id, item.id, 'post')"
                      @click="setVerb(groupSelected.id, item.id, 'post')"
                    >
                      Add
                    </v-btn>
                    <v-btn
                      dark
                      depressed
                      dense
                      small
                      class="mr-2"
                      :color="getColor(groupSelected.id, item.id, 'put')"
                      @click="setVerb(groupSelected.id, item.id, 'put')"
                    >
                      Update
                    </v-btn>
                    <v-btn
                      dark
                      depressed
                      dense
                      small
                      class="mr-2"
                      :color="getColor(groupSelected.id, item.id, 'delete')"
                      @click="setVerb(groupSelected.id, item.id, 'delete')"
                    >
                      Delete
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span class="display">
            <v-avatar size="16" tile color="primary"></v-avatar> Allowed
            <v-avatar size="16" tile color="grey" class="ml-5"></v-avatar> Denied
          </span>
        </template>
      </TheDialog>

      <!-- diálogo de menus -->
      <TheDialog
        v-model="dialogMenuRules"
        title="Atualizar Regras de Menus"
        icon=""
      >
        <template>
          <v-simple-table class="mb-5" width="100%">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="40%">Menus</th>
                  <th class="text-center" width="60%">
                    Regra para {{ groupSelected.name }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="item in menus" :key="item.id" style="width: 100%">
                <!-- <div > -->
                <tr>
                  <td width="60%">{{ item.name }}</td>
                  <td class="text-center">
                    <v-switch
                      :value="
                        getPermission(groupSelected.id, item.id, null, item)
                      "
                      v-model="item.permission"
                      @click="
                        setPermission(
                          groupSelected.id,
                          item.id,
                          null,
                          item.permission
                        )
                      "
                    ></v-switch>
                  </td>
                </tr>
                <tr v-for="menuItem in item.menuItem" :key="menuItem.id">
                  <td width="60%" class="pl-10">{{ menuItem.name }}</td>
                  <td class="text-center">
                    <v-switch
                      :value="
                        getPermission(
                          groupSelected.id,
                          null,
                          menuItem.id,
                          menuItem
                        )
                      "
                      v-model="menuItem.permission"
                      @click="
                        setPermission(
                          groupSelected.id,
                          null,
                          menuItem.id,
                          menuItem.permission
                        )
                      "
                    ></v-switch>
                  </td>
                </tr>
                <!-- </div> -->
              </tbody>
            </template>
          </v-simple-table>
          <span class="display">
            <v-avatar size="16" tile color="primary"></v-avatar> Permitido
            <v-avatar size="16" tile color="grey" class="ml-5"></v-avatar> Negado
          </span>
        </template>
      </TheDialog>
    </v-card>
    
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheInput from '@/components/TheInput'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Group',
  components: {
    TheTitle,
    TheDialog,
    TheInput,
    TheToolTip,
  },
  data: () => ({
    btnIcon: 'mdi-plus',
    text:'Adicionar',
    dialog: false,
    dialogRules: false,
    dialogMenuRules: false,
    search: null,
    group: {},
    header: [
      { text: 'Codigo', value: 'id', width: '5%' },
      { text: 'Nomes', value: 'name' },
      { text: 'Ações', value: 'action', width: '15%' },
    ],
    groups: [],
    routes: [],
    rules: [],
    menus: [],
    menuRules: [],
    error: false,
    edit: false,
    groupSelected: {},
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/group`
      axios
        .get(url)
        .then((res) => {
          this.groups = res.data
        })
        .catch(showError)
    },
    loadRoutes() {
      const url = `${baseApiUrl}/route`
      axios
        .get(url)
        .then((res) => {
          this.routes = res.data
        })
        .catch(showError)
    },
    loadRules() {
      const url = `${baseApiUrl}/rule`
      axios
        .get(url)
        .then((res) => {
          this.rules = res.data
        })
        .catch(showError)
    },
    loadMenus() {
      const url = `${baseApiUrl}/menu`
      axios
        .get(url)
        .then((res) => {
          this.menus = res.data
        })
        .catch(showError)
    },
    loadMenuRules() {
      const url = `${baseApiUrl}/menuRule`
      axios
        .get(url)
        .then((res) => {
          this.menuRules = res.data
        })
        .catch(showError)
    },

    dialogSave() {
      this.group = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.group = item
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/group`
      const groups = this.group
      axios
        .post(url, groups)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.group.id
      const url = `${baseApiUrl}/group/${id}`
      const group = this.group
      delete group.id
      delete group.createdAt
      delete group.updatedAt
      delete group.deletedAt
      axios
        .put(url, group)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/group/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
    setVerb(groupId, routeId, verb) {
      const url = `${baseApiUrl}/rule`
      const rule = {
        idRoute: routeId,
        idGroup: groupId,
        verb: verb,
      }
      rule.type = this.getType(rule)
      axios
        .post(url, rule)
        .then(() => this.loadRules())
        .catch(showError)
    },
    getType(rule) {
      let type = 1
      if (!Array.isArray(this.rules)) {
        return type
      }
      this.rules.forEach((element) => {
        if (
          element.idGroup === rule.idGroup &&
          element.idRoute === rule.idRoute &&
          element.verb === rule.verb
        ) {
          if (element.type === 1) {
            type = 0
          } else {
            type = 1
          }
        }
      })
      return type
    },
    getColor(idGroup, idRoute, verb) {
      let color = 'grey'
      if (!Array.isArray(this.rules)) {
        return color
      }
      this.rules.forEach((element) => {
        if (
          element.idGroup === idGroup &&
          element.idRoute === idRoute &&
          element.verb === verb
        ) {
          if (element.type === 1 || element.type === true) {
            color = 'primary'
          } else {
            color = 'grey'
          }
        }
      })
      return color
    },
    getPermission(idGroup, idMenu, idMenuItem, item) {
      let permission = false
      if (!Array.isArray(this.menuRules)) {
        return false
      }
      this.menuRules.forEach((element) => {
        if (
          element.idGroup === idGroup &&
          ((element.idMenu && element.idMenu === idMenu) ||
            (element.idMenuItem && element.idMenuItem === idMenuItem))
        ) {
          permission = element.permission
        }
      })
      item.permission = permission
      return permission
    },
    setPermission(groupId, menuId, menuItemId, permission) {
      const url = `${baseApiUrl}/menuRule`
      const menuRule = {
        idMenu: menuId,
        idGroup: groupId,
        idMenuItem: menuItemId,
        permission: permission,
      }
      if (!permission) {
        menuRule.permission = false
      }

      axios
        .post(url, menuRule)
        .then(() => this.loadMenuRules())
        .catch(showError)
    },
    editRules(group) {
      this.groupSelected = group
      this.dialogRules = true
    },
    editMenuRules(group) {
      this.groupSelected = group
      this.dialogMenuRules = true
    },
  },
  mounted() {
    this.loadData()
    this.loadRoutes()
    this.loadRules()
    this.loadMenus()
    this.loadMenuRules()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>